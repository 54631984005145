// app/hooks/usePreventUnload.ts

import { useEffect } from "react";
import { useEditorSync } from "~/components/AppContent/state/EditorContext";

export function usePreventUnload() {
  const { isSaving, hasPendingChanges } = useEditorSync();

  const isStop = hasPendingChanges || isSaving;

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isStop) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isStop]);
}
