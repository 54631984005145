import { Button } from "@mantine/core";
import { IconDiamond, IconMessageDots } from "@tabler/icons-react";
import { loader as rootLoader } from "~/root";
import { RenderVideoButton } from "~/components/AppContent/Editor/HeaderControls/RenderVideoButton";
import { AspectRatioSelector } from "~/components/AppContent/Editor/HeaderControls/AspectRatioSelector";
import { UndoRedo } from "~/components/AppContent/Editor/HeaderControls/UndoRedo";
import { Styles } from "~/components/AppContent/Editor/HeaderControls/Styles";
import {useState} from "react";
import {SavingIndicator} from "~/components/AppContent/Editor/HeaderControls/SavingIndicator";
import { Link, useLoaderData } from "@remix-run/react";
import { checkIfUserFree } from "~/utils/checkIfUserFree";
import { getRoute } from "~/routes";

export function EditorElements({ video }) {

  const {currentUserPlan } = useLoaderData<typeof rootLoader>();
  let isUserFree = checkIfUserFree(currentUserPlan);
  return (
    <>
      <SavingIndicator/>
      <UndoRedo video={video} />
      <Styles video={video} />
      <AspectRatioSelector video={video}></AspectRatioSelector>
      {isUserFree ?  <Button
                // variant="light"
                // size="sm"
                component={Link}
                to={getRoute("plan")}
                rightSection={<IconDiamond size="1rem" />}
                // onClick={handleCreateVideo}
                // disabled={navigation.state === "loading"}
                // loading={fetcher.state === "submitting"}
              >
                Upgrade to render
              </Button>: <RenderVideoButton video={video}></RenderVideoButton>}
    </>
  );
}
