import { useFetcher } from '@remix-run/react';
import { useCallback, useEffect, useMemo } from 'react';
import type { AwsRegion, EnhancedErrorInfo } from '@remotion/lambda';
import { getRoute } from '~/routes';
export type StatusResponse = {
	renderId: string;
	done: boolean;
	overallProgress: number;
	outputFile: string | null;
	errors: EnhancedErrorInfo[];
};
export function usePollRenderStatus({
	renderId,
	bucketName,
	videoId,
}: {
	renderId: string;
	bucketName: string;
	videoId: string;

}) {
	const { submit, data } = useFetcher<StatusResponse>();

  // console.log('data of use poll render status', data);

	const checkRenderProgress = useCallback(() => {
		submit(
			{ renderId, bucketName, videoId },
			{
				method: 'post',
				action: getRoute('getRenderProgress'),
			}
		);
	}, [submit, renderId, bucketName, videoId]);

	useEffect(() => {
		checkRenderProgress();
	}, [checkRenderProgress]);

	const done = data?.done ?? false;

	useEffect(() => {
		if (done) {
			return;
		}
		const interval = setInterval(() => {
			checkRenderProgress();
		}, 2000);

		return () => {
			clearInterval(interval);
		};
	}, [checkRenderProgress, done, renderId]);

	const status = useMemo(() => {
		return {
			progress: data,
		};
	}, [data]);

	return status;
}
