import { Button, Group } from "@mantine/core";
import { Link } from "@remix-run/react";
import { getRoute } from "~/routes";
import { IconArrowRight } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

export function NavAuthButtons() {
  const { t } = useTranslation();

  return (
    <Group gap="md">
      <Button component={Link} to={getRoute("login")} variant="outline">
        {t("log_in")}
      </Button>
      <Button
        component={Link}
        to={getRoute("register")}
        variant="filled"
        rightSection={<IconArrowRight size={14} />}
      >
        {t("create_free_account")}
      </Button>
    </Group>
  );
}
