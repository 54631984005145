import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { json, LoaderFunctionArgs } from "@remix-run/node";
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
  isRouteErrorResponse,
} from "@remix-run/react";
import { useChangeLanguage } from "remix-i18next/react";
import { useTranslation } from "react-i18next";
import i18next from "~/services/i18n/i18n.server";
import globalStyles from "~/styles/global.css?url";
import appStyles from "~/styles/app.css?url";
import "@mantine/core/styles.css";
import {
  Button,
  Code,
  ColorSchemeScript,
  createTheme,
  MantineProvider,
} from "@mantine/core";
import AppLayout from "~/components/AppShell/AppLayout";
import React, { useEffect } from "react";
import { myTheme } from "~/theme";
import { createClient } from "~/services/auth/supabase.server";
import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import "@mantine/nprogress/styles.css";

import { db } from "../db";
import {
  metaTable,
  myUsersTable,
  plansTable,
  projectsTable,
  videosTable,
} from "../db/schema";
import { and, eq } from "drizzle-orm";
import { getAuthUser } from "~/services/auth/auth.server";
import { NavigationProgress, nprogress } from "@mantine/nprogress";
import { useNavigation } from "react-router";
import { MyError } from "~/components/AppShell/AppHeader/MyError";
import { EditorProvider } from "~/components/AppContent/state/EditorContext";
export function links() {
  return [
    { title: "Paracast.io" },
    {
      name: "description",
      content: "Create videos for your SaaS and startup and grow!",
    },
    { rel: "stylesheet", href: globalStyles },
    { rel: "stylesheet", href: appStyles },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/favicon/apple-touch-icon.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon/favicon-32x32.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon/favicon-16x16.png',
    },
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: '/favicon/favicon.ico',
    },
    { rel: 'manifest', href: '/favicon/site.webmanifest' },
    {
     rel: 'mask-icon',
     href: '/favicon/safari-pinned-tab.svg',
     color: '#5bbad5',
    },
  ];
}
const theme = myTheme;

export async function loader({ request, params }: LoaderFunctionArgs) {
  const user = await getAuthUser(request);
  let myUser: any = null;

  // Fetch plans regardless of user authentication
  const plans = await db
    .select()
    .from(plansTable)
    .orderBy(plansTable.priceUsdPerPeriod);

// Fetch meta data
  const meta = await db.select().from(metaTable).limit(1).then((rows) => rows[0]);


  let currentUserPlan;
  let videos;
  let projects;
  if (user) {
    // if we have the user in the supabase, we will fetch users' data (myUser, stored in another table) and find the currentUserPlan object
    const allMyUsers = await db
      .select()
      .from(myUsersTable)
      .where(eq(myUsersTable.auth_user_id, user.id))
      .limit(1);
    myUser = allMyUsers[0];

    if (myUser) {
      // Use Promise.all for concurrent fetching of videos and projects
      [videos, projects] = await Promise.all([
        db
          .select()
          .from(videosTable)
          .where(
            and(
              eq(videosTable.ownerId, myUser.auth_user_id),
              eq(videosTable.isDeleted, false)
            )
          ),
        db
          .select()
          .from(projectsTable)
          .where(eq(projectsTable.ownerId, myUser.auth_user_id)),
      ]);

      currentUserPlan = plans.find((plan) => plan.id === myUser.currentPlanId);

      // sort dy date: newest first
      videos = [...videos].sort(
        (a, b) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      );
    }

    // currentUserPlan = plans.find((plan) => plan.id === myUser.currentPlanId);
    //
    // videos = await db
    //   .select()
    //   .from(videosTable)
    //   .where(
    //     and(
    //       eq(videosTable.userId, myUser.auth_user_id),
    //       eq(videosTable.isDeleted, false)
    //     )
    //   );
    //
    // projects = await db
    //   .select()
    //   .from(projectsTable)
    //   .where(eq(myUser.auth_user_id, user.id));
  }

  const locale = params.lang || "en";

  return json<RootLoaderData>({
    // go to db/schema.ts to learn about 2 users
    user,
    myUser,
    locale,
    plans,
    currentUserPlan,
    videos,
    projects,
    meta
  });
}
export type RootLoaderData = {
  user: any;
  myUser: any;
  locale: string;
  plans: any[];
  currentUserPlan: any;
  videos: any[];
  projects: any[];
  meta: any
};
export let handle = {
  i18n: "common",
};

export function Layout({ children }: { children: React.ReactNode }) {
  // let { locale } = useLoaderData<typeof loader>();
  // let { i18n } = useTranslation();

  // useChangeLanguage(locale);

  // const { user } = useLoaderData<typeof loader>();
  const navigation = useNavigation();
  useEffect(() => {
    if (navigation.state === "loading") {
      nprogress.start();
    } else {
      nprogress.complete();
    }
  }, [navigation.state]);

  // const defaultEditorState = {
  //   styles: {
  //     accentColor: "#FF8C00",
  //     font: "Arial",
  //   },
  //   aspectRatio: "horizontal", // You might want to store this in the projects table
  //   frames: [1, 2, 3, 4, 5], // You might want to store this in the projects table
  // };

  return (
    // <html lang={locale} dir={i18n.dir()}></html>
    (<html>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <meta property="og:title" content="Grow your startup — paracast.io" />
        <meta property="og:description" content="Use Paracast.io to make promo videos for your SaaS or mobile app." />
        <meta property="og:image" content="https://public-assets.paracast.io/og.jpg" />
        <meta property="og:url" content="https://use.paracast.io" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Grow your startup — paracast.io" />
        <meta name="twitter:description" content="Use Paracast.io to make promo videos for your SaaS or mobile app." />
        <meta name="twitter:image" content="https://public-assets.paracast.io/og.jpg" />
        <Meta />
        <Links />
        <ColorSchemeScript defaultColorScheme="auto" />
      </head>
      <body>
        <MantineProvider theme={theme} defaultColorScheme="dark">
          <Notifications />
          <NavigationProgress />
          <EditorProvider>
            <AppLayout>{children}</AppLayout>
          </EditorProvider>
        </MantineProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>)
  );
}

function App() {
  return <Outlet />;
}

export default withSentry(App);
export function ErrorBoundary() {
  const error = useRouteError();
  console.log("error: ", error);

  captureRemixErrorBoundaryError(error);

  return <MyError error={error}></MyError>;
}