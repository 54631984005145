// app/components/AppContent/Editor/Styles.tsx

import React, { useRef, useState } from "react";
import {
  Button,
  ColorPicker,
  ColorSwatch,
  Popover,
  rem,
  Select,
  TextInput,
  Title,
  SegmentedControl,
  Center,
} from "@mantine/core";
import {
  IconChevronDown,
  IconMoon,
  IconPalette,
  IconSun,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useEditorState, useEditorSync } from "~/components/AppContent/state/EditorContext";
import { FontPicker } from "~/components/AppContent/Editor/HeaderControls/FontPicker/FontPicker";
import { useClickOutside } from "@mantine/hooks";

export function Styles({ video }) {
  const { t } = useTranslation();
  const { editorState, setEditorState } = useEditorState();
  const { isSaving } = useEditorSync();
  const [opened, setOpened] = useState(false);
  const popoverRef = useRef(null);

  useClickOutside(() => setOpened(false), null, [popoverRef.current]);

  if (!editorState || !video) {
    return null;
  }

  const handleColorChange = (newColor: string) => {
    setEditorState((prevState) => ({
      ...prevState,
      styles: {
        ...prevState.styles,
        accentColor: newColor,
      },
    }));
  };

  const handleHexInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    if (/^#[0-9A-Fa-f]{0,6}$/.test(newValue)) {
      handleColorChange(newValue);
    }
  };

  const handleFontChange = (newFont: string) => {
    setEditorState((prevState) => ({
      ...prevState,
      styles: {
        ...prevState.styles,
        font: newFont,
      },
    }));
  };

  const handleBackgroundColorChange = (newBackgroundColor: string) => {
    setEditorState((prevState) => ({
      ...prevState,
      styles: {
        ...prevState.styles,
        backgroundColorID: newBackgroundColor,
      },
    }));
  };

  const popularColors = [
    "#FF8C00",
    "#4682B4",
    "#9932CC",
    "#000080",
    "#303030",
    "#32CD32",
    "#1E90FF",
    "#FF6347",
    "#8A2BE2",
    "#228B22",
    "#000000",
    "#FFD700",
    "#6A5ACD",
    "#0000FF",
    "#9370DB",
    "#4169E1",
    "#FF4500",
    "#3CB371",
    "#00CED1",
    "#7B68EE",
    "#20B2AA",
  ];

  return (
    <Popover width={280} withArrow position="bottom-end" offset={2}>
      <Popover.Target>
        <Button
          variant="default"
          className="text-color"
          leftSection={<IconPalette size="1rem" />}
          rightSection={<IconChevronDown size="1rem" />}
          // disabled={isSaving}
        >
          {t("Edit styles")}
        </Button>
      </Popover.Target>
      <Popover.Dropdown pt={0} pb="lg">
        <Title size="h4" order={3} pt="sm">
          {t("Accent color")}
        </Title>

        <ColorPicker
          format="hex"
          value={editorState.styles.accentColor}
          onChange={handleColorChange}
          withPicker={false}
          fullWidth
          swatches={popularColors}
        />

        <TextInput
          pt="sm"
          value={editorState.styles.accentColor}
          onChange={handleHexInputChange}
          placeholder="#FFFFFF"
          maxLength={7}
          leftSection={
            <ColorSwatch
              color={editorState.styles.accentColor}
              size={rem("1rem")}
            />
          }
        />
        <Title size="h4" order={3} pt="lg" pb="xs">
          {t("Font")}
        </Title>
        {/*<Select*/}
        {/*  placeholder={t("Pick value")}*/}
        {/*  data={["Arial", "Roboto", "Archivo", "Times"]}*/}
        {/*  value={editorState.styles.font}*/}
        {/*  onChange={handleFontChange}*/}
        {/*/>*/}
        <FontPicker></FontPicker>
        <Title size="h4" order={3} pt="lg" pb="xs">
          {t("Background color")}
        </Title>
        <SegmentedControl
          value={editorState.styles.backgroundColorID}
          onChange={handleBackgroundColorChange}
          size={"xs"}
          data={[
            {
              label: (
                <Center style={{ gap: 10 }}>
                  <IconSun style={{ width: rem(16), height: rem(16) }} />
                  <span>White</span>
                </Center>
              ),
              value: "white",
            },
            {
              label: (
                <Center style={{ gap: 10 }}>
                  <IconMoon style={{ width: rem(16), height: rem(16) }} />
                  <span>Black</span>
                </Center>
              ),
              value: "black",
            },
          ]}
          fullWidth
        />
      </Popover.Dropdown>
    </Popover>
  );
}
