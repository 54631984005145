import { Button, Text, useMantineColorScheme } from "@mantine/core";
import { Link } from "@remix-run/react";
import { getRoute } from "~/routes";
import { constants } from "~/constants";

export function LogoButton() {
  return (
    <Button
      component={Link}
      to={getRoute("home")}
      variant="subtle"
      leftSection={
        <img src={constants.PROJECT_BASIC_LOGO_URL} height={44} alt="Logo" />
      }
    >
      <Text fw={700} className={"text-color"}>
        {constants.PROJECT_NAME_CAPITALISED}
      </Text>
    </Button>
  );
}
