// Assuming this file is named UndoRedo.tsx
import { ActionIcon, rem } from "@mantine/core";
import { IconArrowBackUp, IconArrowForwardUp } from "@tabler/icons-react";
import { useEditorState } from "~/components/AppContent/state/EditorContext";

export function UndoRedo({ video }) {
  const { undo, redo, canUndo, canRedo } = useEditorState();

  const noUndoNoRedo = !canUndo && !canRedo;

  if (noUndoNoRedo) {
    return null;
  } else {
    return (
      <ActionIcon.Group>
        <ActionIcon
          variant="default"
          size="lg"
          aria-label="Undo"
          title="Undo"
          onClick={undo}
          disabled={!canUndo}
        >
          <IconArrowBackUp style={{ width: rem(20) }} />
        </ActionIcon>

        <ActionIcon
          variant="default"
          size="lg"
          aria-label="Redo"
          title="Redo"
          onClick={redo}
          disabled={!canRedo}
        >
          <IconArrowForwardUp style={{ width: rem(20) }} />
        </ActionIcon>
      </ActionIcon.Group>
    );
  }
}
