// app/components/AppContent/Editor/HeaderControls/SavingIndicator.tsx

import { Tooltip } from "@mantine/core";
import {
  IconCircleCheck,
  IconCircleDashedCheck,
  IconCircleDashed,
} from "@tabler/icons-react";
import { useEditorSync } from "~/components/AppContent/state/EditorContext";
import { usePreventUnload } from "~/utils/usePreventUnload";

export function SavingIndicator() {
  const { isSaving, hasPendingChanges } = useEditorSync();
  usePreventUnload();

  if (isSaving) {
    return (
      <Tooltip label="Syncing your video data now...">
        <IconCircleDashedCheck size="1.2rem" />
      </Tooltip>
    );
  } else if (hasPendingChanges) {
    return (
      <Tooltip label="Changes detected, preparing to save...">
        <IconCircleDashed size="1.2rem" />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip label="Your video is saved!">
        <IconCircleCheck size="1.2rem" />
      </Tooltip>
    );
  }
}
