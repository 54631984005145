import { Menu, Text, ActionIcon, Group } from "@mantine/core";
import {
  IconSettings,
  IconLogout,
  IconCreditCard,
  IconDiamond,
  IconUser,
} from "@tabler/icons-react";
import { Link, useLoaderData, useFetcher } from "@remix-run/react";
import { ToggleDarkTheme } from "~/components/AppShell/ToggleDarkTheme";
import { HelpButton } from "~/components/AppShell/HelpButton";
import { useTranslation } from "react-i18next";
import { loader } from "~/root";
import { getRoute } from "~/routes";

export function UserDropdown() {
  const { t } = useTranslation();
  const { user, myUser, plans, currentUserPlan } =
    useLoaderData<typeof loader>();
  const logoutFetcher = useFetcher();

  const handleLogout = () => {
    logoutFetcher.submit(null, { method: "post", action: getRoute("logout") });
  };

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <ActionIcon
          variant="default"
          size={"lg"}
          component={"span"}
          title={t("toggle_help_chat_hint")}
        >
          <IconUser size="1rem" />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>
          <Text size="sm">{user?.email || "test@gmail.com"}</Text>
        </Menu.Label>
        {/* <Menu.Divider />
        <Menu.Item
          component={Link}
          to={getRoute("settings")}
          leftSection={<IconSettings size={"1rem"} />}
        >
          {t("settings")}
        </Menu.Item> */}
        <Menu.Item
          component={Link}
          to={getRoute("plan")}
          leftSection={<IconDiamond size={"1rem"} />}
          rightSection={
            <Text size="sm" c="dimmed">
              {currentUserPlan?.title || t("free")}
            </Text>
          }
        >
          {t("plan")}
        </Menu.Item>

        <Menu.Divider />
        <Menu.Item>
          <Group gap="xs">
            <ToggleDarkTheme />
            <HelpButton />
          </Group>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          leftSection={<IconLogout size={"1rem"} />}
          color="red"
          onClick={handleLogout}
        >
          {t("logout")}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
