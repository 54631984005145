import { ActionIcon, useMantineColorScheme } from "@mantine/core";
import { IconSun, IconMoon } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export function ToggleDarkTheme() {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const [currentColorSchemeReact, setCurrentColorSchemeReact] =
    useState("light");

  useEffect(() => {
    setCurrentColorSchemeReact(colorScheme);
  }, [colorScheme]);

  let { t } = useTranslation();

  return (
    <>
      <ActionIcon
        className={"hide-on-dark"}
        variant="default"
        onClick={(e) => {
          e.stopPropagation();
          toggleColorScheme();
        }}
        component={"span"}
        title={t("toggle_dark_mode_hint")}
      >
        <IconSun size="1rem" />
      </ActionIcon>
      <ActionIcon
        className={"hide-on-light"}
        variant="default"
        onClick={(e) => {
          e.stopPropagation();
          toggleColorScheme();
        }}
        component={"span"}
        title={t("toggle_dark_mode_hint")}
      >
        <IconMoon size="1rem" />
      </ActionIcon>
    </>
  );
}
