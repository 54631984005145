import {
  Badge,
  Button,
  Menu,
  Pill,
  Text,
  useMantineColorScheme,
} from "@mantine/core";
import { Link, useLoaderData } from "@remix-run/react";
import { getRoute } from "~/routes";
import { constants } from "~/constants";
import {
  IconChevronDown,
  IconVideo,
  IconArrowRight,
  IconPlus,
} from "@tabler/icons-react";
import { loader } from "~/root";

// Helper function to truncate title
const truncateTitle = (title: string, maxLength: number = 20) => {
  return title.length > maxLength ? title.slice(0, maxLength) + "..." : title;
};

export function MyVideosMenu() {
  const { videos } = useLoaderData<typeof loader>();

  const recentDrafts = videos
    .filter((video) => video.status === "draft")
    .slice(0, 5);

  const justRendered = videos
    .filter((video) => video.status === "ready")
    .slice(0, 5);

  return (
    <>
      <Menu shadow="md">
        <Menu.Target>
          <Button
            variant="subtle"
            className={"text-color"}
            leftSection={<IconVideo size={"1rem"} />}
            rightSection={<IconChevronDown size={"1rem"} />}
          >
            {"My videos"}
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>
            <Text size="sm">{"Recent drafts"}</Text>
          </Menu.Label>
          {recentDrafts.map((video) => (
            <Menu.Item
              key={video.id}
              component={Link}
              leftSection={<IconVideo size={"1rem"} />}
              to={getRoute("video", { video_slug: video.slug })}
            >
              {truncateTitle(video.title)}
            </Menu.Item>
          ))}
          <Menu.Divider />
          {justRendered.length > 0 && (
            <>
              <Menu.Label>
                <Text size="sm">{"Just rendered"}</Text>
              </Menu.Label>
              {justRendered.map((video) => (
                <Menu.Item
                  key={video.id}
                  component={Link}
                  leftSection={<IconVideo size={"1rem"} />}
                  to={getRoute("video", { video_slug: video.slug })}
                >
                  {truncateTitle(video.title)}
                </Menu.Item>
              ))}
              <Menu.Divider />
            </>
          )}
          <Menu.Item
            variant="subtle"
            className={"text-color"}
            component={Link}
            to={getRoute("videos")}
            rightSection={<IconArrowRight size={"1rem"} />}
          >
            {`View all ${videos.length}`}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
}
