import { Affix, Button } from "@mantine/core";
import { IconMessageDots, IconPlus } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useFetcher, useNavigate, useRouteLoaderData } from "@remix-run/react";
import { getRoute } from "~/routes";
import { useNavigation } from "react-router";
import { useEffect } from "react";
import { RootLoaderData } from "~/root";
import { style } from "@vanilla-extract/css";

export function CreateVideoButton() {
  let { t } = useTranslation();
  const data = useRouteLoaderData("root") as RootLoaderData;
  const { projects } = data;

  const defaultProject = projects[0];
  const fetcher = useFetcher();
  const navigation = useNavigation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (fetcher.data && fetcher.data.success) {
  //     navigate(getRoute("video", { video_slug: fetcher.data.video.slug }), {
  //       // read about the skipLoader flag here: app/routes/videos.$slug.tsx
  //       state: { video: fetcher.data.video, skipLoader: true },
  //       replace: true,
  //     });
  //   }
  // }, [fetcher.data, navigate]);

  useEffect(() => {
    if (fetcher.data && fetcher.data.success) {
      //  read about the skipLoader flag here: app/routes/videos.$slug.tsx
      navigate(
        `${getRoute("video", {
          video_slug: fetcher.data.video.slug,
        })}?skipLoader=true`,
        {
          state: { video: fetcher.data.video },
          replace: true,
        }
      );
    }
  }, [fetcher.data, navigate]);

  const handleCreateVideo = () => {
    const defaultProjectStyle = {
      accentColor: defaultProject.accentColor,
      font: defaultProject.font,
      backgroundColorID: defaultProject.backgroundColorID,
    };
    const emptyVideoFrameSet = {
      frames: [],
    };
    fetcher.submit(
      {
        title: "Blank video",
        projectId: defaultProject.id,
        // get styles from the project (each video hsa its own styles but we need something as default
        // we store last used colors/fonts (styles) on the project, and use here when we create a new video
        style: JSON.stringify(defaultProjectStyle),
        // we need to create a video with an empty array of frames
        content: JSON.stringify(emptyVideoFrameSet),
      },
      { method: "post", action: getRoute("createVideo") }
    );
  };
  return (
    <>
      {/*<Affix position={{ bottom: 40, right: 40 }}>*/}
      <Button
        variant="filled"
        size="sm"
        rightSection={<IconPlus size="1rem" />}
        onClick={handleCreateVideo}
        disabled={navigation.state === "loading"}
        loading={fetcher.state === "submitting"}
      >
        Create video
      </Button>
      {/*</Affix>*/}
    </>
  );
}
