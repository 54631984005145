import { Outlet } from "@remix-run/react";
import { AppShell, AppShellFooter, Burger } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import AppFooter from "~/components/AppShell/AppFooter";
import AppHeader from "~/components/AppShell/AppHeader/AppHeader";
export default function Layout() {
  return (
    <AppShell
      padding="xl"
      header={{ height: 60 }} // Adjust this value to match your desired header height
    >
      <AppShell.Header p="xs">
        <AppHeader></AppHeader>
      </AppShell.Header>
      <AppShell.Main pb={"lg"}>
        <Outlet />
      </AppShell.Main>
      <AppFooter></AppFooter>
    </AppShell>
  );
}
